<template>
    <div class="AliPayAuth" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form @submit.native.prevent :inline="true" style="text-align: right" v-model="form" size="small">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="机构名称">
                            <el-select placeholder="机构名称" v-model="form.deptCode" filterable>
                                <el-option value label="请选择" />
                                <el-option
                                    v-for="dept in depts"
                                    :label="dept.name"
                                    :value="dept.code"
                                    :key="dept.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="授权状态">
                            <el-select v-model="form.status">
                                <el-option value label="请选择" />
                                <el-option label="未授权" value="0" />
                                <el-option label="已授权" value="1" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button type="primary" @click="handleQuerytable(false)" size="small">查询</el-button>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                ref="table"
                border
                stripe
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="520"
            >
                <el-table-column label="序号" type="index" width="60" align="center" />
                <el-table-column label="机构名称" width="240" prop="deptName" />
                <el-table-column label="子商户号" width="240" prop="userId" />
                <el-table-column label="状态" width="120" prop="status" key="status">
                    <template slot-scope="scope">
                        <span>{{ scope.row.status ? '已授权' : '未授权' }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="180" header-align="center" prop="operate" key="operate">
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            type="primary"
                            @click="handleEmpower(scope.row)"
                            :disabled="scope.row.status == 1"
                            >授权
                        </el-button>
                        <el-button
                            size="mini"
                            type="primary"
                            @click="unbound(scope.row)"
                            :disabled="scope.row.status == 0"
                            v-if="hasPrivilege('menu.finance.ali.unbound')"
                            >解除授权
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="block">
                <ef-pagination
                    @pageChange="handleSizeChange"
                    :total="page.total"
                    :default-limit="form.limit"
                    :current-page="form.page"
                />
            </div>
        </el-card>
    </div>
</template>

<script>
import Util from 'js/Util';
import UrlUtils from 'js/UrlUtils';
import { DeptTypeEnum } from 'js/DeptCommon';
import { Message } from 'element-ui';
import EfPagination from 'components/EfPagination';

export default {
    name: 'AliPayAuth',
    components: {
        EfPagination,
    },
    data() {
        return {
            form: {
                deptCode: '',
                valid: false,
                page: 1,
                limit: 20,
                status: '',
                currentPage: 1,
            },
            url: {
                page: '/system/dept/queryExtend',
                status: '/finance/pay/aliPay/authStatus',
                handleEmpower: '/finance/pay/aliPay/auth',
            },
            tableurl: {
                page: '/finance/pay/aliPay/page',
            },
            depts: [],
            tableData: [],
            page: {
                total: 0,
            },
        };
    },
    mounted() {
        this.handleQuerytable(false);
        this.$efApi.deptApi.managedDeptsByDeptType(DeptTypeEnum.STORE).then((rst) => {
            this.depts = rst;
        });
    },
    methods: {
        //解除权限绑定
        unbound(row) {
            this.$confirm('确认解除支付宝授权吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    this.$http.post('/finance/pay/aliPay/unbound/' + row.code).then((rst) => {
                        if (rst.data.status == 200) {
                            Message.success({ message: '保存成功' });
                            this.handleQuerytable(false);
                        } else {
                            Message.error({ message: '操作失败' + rst.data.status });
                        }
                    });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消解除',
                    });
                });
        },
        handleSelectType() {
            this.handleQuerytable(false);
            this.handleQuery();
        },
        handleEmpower(row) {
            this.$confirm('确认进行支付宝授权吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    UrlUtils.QueryRemote(this, '/finance/pay/aliPay/authUrl/' + row.deptCode, (data) => {
                        window.open(data);
                        this.$confirm('确认是否授权成功?', '提示', {
                            confirmButtonText: '成功',
                            cancelButtonText: '失败',
                            type: 'warning',
                        })
                            .then(() => {
                                this.form.deptCode = row.deptCode;
                                this.handleQuerytable(true);
                            })
                            .catch(() => {
                                this.form.deptCode = row.deptCode;
                                this.handleQuerytable(true);
                            });
                    });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消授权',
                    });
                });
        },
        handleQuerytable(showMessage = false) {
            const _this = this;
            const _params = { params: _this.form };
            Util.queryTable(_this, _this.tableurl.page, _params, (rst) => {
                _this.tableData = rst.data;
                if (_this.page) _this.page.total = rst.count;
                if (showMessage && rst.data.length > 0) {
                    const oneData = rst.data[0];
                    if (oneData.status) {
                        this.$message.success(`${oneData.deptName}--已授权成功`);
                    } else {
                        this.$message.error(`${oneData.deptName}--授权失败`);
                    }
                }
            });
        },
        handleQuery() {
            const _this = this;
            _this.$http
                .post(_this.url.status + '/' + _this.form.deptCode)
                .then((rst) => {
                    _this.form.valid = rst.data['data'];
                })
                .catch((rst) => {
                    _this.form.valid = false;
                });
        },
        handleSizeChange(page, limit) {
            this.form.page = page;
            this.form.limit = limit;
            this.handleQuerytable(false);
        },
    },
};
</script>

<style scoped>
.AliPayAuth .el-form-item {
    margin-bottom: 0;
}

.AliPayAuth .el-table th.gutter {
    display: table-cell;
}
</style>
