<template>
    <span>
        <el-pagination
            :page-sizes="pageSizes"
            :page-size.sync="limit"
            :layout="pageStyle"
            :total="total"
            :current-page.sync="page"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        />
    </span>
</template>

<script>
export default {
    name: 'EfPagination',
    props: {
        total: {
            type: Number,
            default: 0,
        },
        defaultLimit: {
            type: Number,
            default: null,
        },
        sizesStyleFlag: {
            type: Boolean,
            default: true,
        },
        currentPage: {
            type: Number,
            default: 1,
        },
        pageSizes: {
            type: Array,
            default: function () {
                return [10, 20, 50, 100, 200];
            },
        },
    },
    data() {
        return {
            pageStyle: 'prev, pager, next,total,jumper' + (this.sizesStyleFlag ? ',sizes' : ''),
            page: 1,
            limit: 20,
        };
    },
    watch: {
        currentPage: {
            handler(n, o) {
                this.page = n || 1;
            },
            immediate: true,
        },
    },
    mounted() {
        if (this.defaultLimit != null) {
            //已赋值
            this.limit = this.defaultLimit;
            if (this.pageSizes.indexOf(this.defaultLimit) < 0) {
                //不存在，新增
                this.pageSizes.push(this.defaultLimit);
                //从小到大排序
                this.pageSizes.sort(function (a, b) {
                    return a - b;
                });
            }
        } else {
            //未设置，采用默认
            this.$emit('pageChange', this.page, this.limit);
        }
        //加载完成
        this.$emit('update:componentLoadingCompleteFlag', true);
    },
    methods: {
        handleSizeChange(pageLimit) {
            this.page = 1;
            this.$emit('pageChange', this.page, pageLimit);
        },
        handleCurrentChange(currentPage) {
            this.$emit('pageChange', currentPage, this.limit);
        },
    },
};
</script>
